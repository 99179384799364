<template>
  <div class="shopList">
    <div class="manage-header">
      <el-form :model="userForm" :inline="true" ref="userForm">
        <el-form-item prop="shopName" label="店铺名称">
          <el-input v-model="userForm.shopName" placeholder="请输入店铺名称"></el-input>
        </el-form-item>

        <el-form-item label="电商平台" prop="shopType">
          <el-select v-model="userForm.shopType" placeholder="请选择电商平台">
            <el-option
              v-for="item in optionImg"
              :key="item.url"
              :label="item.label"
              :value="item.value"
            >
              <img :src="item.url" style="height: 28px" />
              <span style="font-weight: bold">{{ item.label }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-card class="box-card">
      <el-table :data="shopList" stripe style="width: 100%">
        <el-table-column prop="id" label="#" width="180"> </el-table-column>
        <el-table-column
          prop="shopInfo"
          label="店铺信息"
          width="360"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-row prop="type">
              <img :src="scope.row.shopInfo.typeUrl" style="height: 28px" />
            </el-row>
            <!-- <el-row prop="grade">{{ scope.row.shopInfo.grade }}</el-row> -->
            <el-row prop="shopName">
              <span style="font-weight: bold"
                >店铺：{{ scope.row.shopInfo.shopName }}</span
              >
            </el-row>
            <el-row prop="apiKey" label="apiKey">
              <span>APIKEY：{{ scope.row.shopInfo.apiKey }}</span>
            </el-row>

            <el-row prop="notes" label="notes">
              <span>备注：{{ scope.row.shopInfo.notes }}</span>
            </el-row>

            <el-row>
              <span v-if="isShowApiPoolButton"
                >apiKey池：{{ scope.row.apiKeysCount }}</span
              >
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop="officialStatus" label="官方状态">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.officialStatus === 1 ? 'success' : 'warning'"
              disable-transitions
            >
              {{ scope.row.officialStatus === 1 ? "已开通" : "未开通" }}
            </el-tag>
            <!-- <i class="el-icon-refresh-right" style="color: #409EFF; cursor:pointer"></i> -->
          </template>
        </el-table-column>
        <el-table-column prop="status" label="服务状态">
          <template slot-scope="scope">
            <el-tag
              :type="
                scope.row.status === 1 || scope.row.status === 3 ? 'danger' : 'success'
              "
              disable-transitions
            >
              {{ scope.row.status === 1 || scope.row.status === 3 ? "过期" : "生效" }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="deadline" label="调价开关">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.switch"
              active-color="#00A854"
              active-text="启动"
              :active-value="1"
              inactive-color="#F04134"
              inactive-text="禁止"
              :inactive-value="0"
              @change="changeSwitch(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column width="190" prop="deadline" label="服务截止时间">
        </el-table-column>
        <el-table-column fixed="right" label="采集库" width="100">
          <template v-slot:default="slotProps">
            <router-link 
              v-if="slotProps.row && slotProps.row.shopInfo && slotProps.row.shopInfo.showCollectionLib" 
              :to="'/products-list/' + slotProps.row.id"
            >
              <el-button size="mini" type="success" class="collection-lib-btn">
                <i class="el-icon-goods"></i> 采集库
              </el-button>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <router-link :to="'/offer-list/' + scope.row.id">
              <el-button size="mini" type="primary">查看产品</el-button>
            </router-link>
            <router-link :to="'/order-list/' + scope.row.id">
              <el-button size="mini" style="width: 80px; margin-top: 10px" type="primary">查看订单</el-button>
            </router-link>
            <el-button size="mini" style="width: 80px; margin-top: 10px" type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除
            </el-button>
            <el-button
              size="mini"
              style="width: 80px; margin-top: 10px; margin-left: 0"
              type="warning"
              @click="handleUpdate(scope.$index, scope.row)"
              >修改
            </el-button>

            <el-button
              size="mini"
              v-if="isShowApiPoolButton"
              style="width: 80px; margin-top: 10px; margin-left: 0"
              type="warning"
              @click="openApiKeyDialog(scope.$index, scope.row)"
              >API池
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>

      <el-dialog
        :close-on-click-modal="false"
        title="修改信息"
        :visible.sync="visible"
        width="50%"
        @close="dialogClosed"
      >
        <el-form :model="shopInfo" :rules="rule" ref="shopInfo" label-width="100px">
          <el-form-item label="店铺名称:" prop="shopName">
            <el-input
              style="width: 350px"
              v-model.trim="shopInfo.shopName"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="店铺类型:" prop="typeName">
            <el-input
              style="width: 350px"
              v-model.trim="shopInfo.typeName"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="apiKey:" prop="apiKey">
            <el-input style="width: 350px" v-model.trim="shopInfo.apiKey"></el-input>
            <h3 style="color: red">修改 apiKey 之后请务必重新登录账号</h3>
          </el-form-item>
          <el-form-item label="备注:" prop="notes">
            <el-input style="width: 350px" v-model.trim="shopInfo.notes"></el-input>
          </el-form-item>
        </el-form>
        <el-button :loading="loading" size="small" type="primary" @click="save"
          >保存</el-button
        >
      </el-dialog>

      <el-dialog
        :visible.sync="dialogVisible"
        title="API Key管理"
        width="40%"
        @close="closeApiKeyDialog"
      >
        <!-- 用于输入API keys的文本区域 -->
        <el-input
          type="textarea"
          :rows="4"
          placeholder="输入API keys，每行一个"
          v-model="apiKeysInput"
          @input="handleInput"
        >
        </el-input>

        <!-- 已输入的API keys展示 -->
        <div v-if="apiKeysList.length > 0" style="margin-top: 15px">
          <p>已保存的API keys:</p>
          <el-tag
            v-for="(key, index) in apiKeysList"
            :key="index"
            closable
            @close="removeApiKey(index)"
          >
            {{ key }}
          </el-tag>
        </div>

        <!-- 对话框底部操作按钮 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveApiKeys">保存</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      showCollectionLib: false,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      value1: true,
      value2: true,
      shopList: [],
      optionImg: [
        {
          label: "全部",
          value: "all",
          url: "",
        },
        {
          label: "worten",
          value: "worten",
          url: require("../../assets/logo-worten.png"),
        },
        {
          label: "fnac",
          value: "fnac",
          url: require("../../assets/logo-fnac.png"),
        },
        {
          label: "catch",
          value: "catch",
          url: require("../../assets/logo-catch.png"),
        },
        {
          label: "eprice",
          value: "eprice",
          url: require("../../assets/logo-eprice.png"),
        },
        {
          label: "fyndiq",
          value: "fyndiq",
          url: require("../../assets/logo-fyndiq.png"),
        },
        {
          label: "onbuy",
          value: "onbuy",
          url: require("../../assets/logo-onbuy.png"),
        },
        {
          label: "rdc",
          value: "rdc",
          url: require("../../assets/logo-rdc.png"),
        },
      ],
      userForm: {
        shopName: "",
        shopType: "",
      },
      visible: false,
      loading: false,
      shopInfo: {
        shopName: "",
        typeName: "",
        apiKey: "",
        notes: "",
        shopId: "",
      },
      rule: {
        apiKey: [{ required: true, message: "请输入 apiKey", trigger: "blur" }],
      },
      dialogVisible: false, // 控制对话框显示
      apiKeysInput: "", // 对话框中的输入内容
      apiKeysList: [], // 存储已输入的API keys列表
      apiKeyRoomShopId: "",
      isShowApiPoolButton: false,
    };
  },
  async created() {
    const res = await this.getShopList();
    this.shopList = res.list;
    this.pagination.total = res.total;
    this.shopList.forEach((i) => {
      i.shopInfo.typeUrl = require(`../../assets/logo-${i.shopInfo.typeName}.png`);
      // this.optionImg.push({
      //   label: i.shopInfo.typeName,
      //   value: i.shopInfo.typeName,
      //   url: i.shopInfo.typeUrl
      // })
    });
    await this.checkUserAuthorization();
    this.loading = false;
  },
  methods: {
    ...mapActions([
      "getUserInfo",
      "getShopList",
      "deleteShop",
      "updateShop",
      "updateShopSwitch",
      "updateApiKeyList",
      "getApiKeyList",
    ]),
    async resetSearch() {
      this.$refs.userForm.resetFields();
      this.pagination.currentPage = 1;
      const res = await this.getShopList();
      this.shopList = res.list;
      this.pagination.total = res.total;
      this.shopList.forEach((i) => {
        i.shopInfo.typeUrl = require(`../../assets/logo-${i.shopInfo.typeName}.png`);
      });
      this.loading = false;
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.pagination.currentPage = 1; // 重置到第一页
      this.search();
    },
    // 处理页码变化
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.search();
    },
    async search() {
  this.loading = true;
  try {
    const res = await this.getShopList({
      ...this.userForm,
      page: this.pagination.currentPage,
      pageSize: this.pagination.pageSize
    });
    
    this.shopList = res.list;
    this.pagination.total = res.total;

    // 更新店铺图标
    this.shopList.forEach(i => {
      i.shopInfo.typeUrl = require(`../../assets/logo-${i.shopInfo.typeName}.png`)
    });
  } catch (error) {
    console.error('Failed to fetch shop list:', error);
    this.$message.error('获取店铺列表失败');
  } finally {
    this.loading = false;
  }
},
    async handleDelete(index, row) {
      this.$confirm("此操作将永久删除该店铺, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await this.deleteShop({ shopId: row.id });
          this.shopList.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async handleUpdate(index, info) {
      this.visible = true;
      this.shopInfo = info.shopInfo;
      this.shopInfo.shopId = info.id;
    },
    async save() {
      this.$refs.shopInfo.validate(async (valid) => {
        if (!valid) return false;
        try {
          this.loading = true;
          await this.updateShop({
            shopId: this.shopInfo.shopId,
            apiKey: this.shopInfo.apiKey,
            notes: this.shopInfo.notes,
          });
          this.visible = false;
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          this.shopList = await this.getShopList();
          this.shopList.forEach((i) => {
            i.shopInfo.typeUrl = require(`../../assets/logo-${i.shopInfo.typeName}.png`);
          });
          this.loading = false;
        } catch (error) {
          this.loading = false;
          this.$message({
            type: "error",
            message: "修改失败!",
          });
        }
      });
    },
    dialogClosed() {
      this.$refs.shopInfo.resetFields();
    },
    async changeSwitch(data) {
      await this.updateShopSwitch({
        shopId: data.id,
        switch: data.switch,
      });
    },
    handleInput() {
      // 将输入框的内容分割成数组，并去重更新列表
      const newApiKeys = this.apiKeysInput.split("\n").map((key) => key.trim());
      this.apiKeysList = Array.from(new Set(newApiKeys.filter((key) => key !== "")));
    },
    async openApiKeyDialog(index, info) {
      this.dialogVisible = true; // 打开对话框
      // 如果之前有输入，回显到输入框
      const apiKeyList = await this.getApiKeyList({ shopId: info.id });
      this.apiKeysList = apiKeyList;
      this.apiKeysInput = apiKeyList.join("\n");
      this.apiKeyRoomShopId = info.id;
    },
    closeApiKeyDialog() {
      this.apiKeysInput = ""; // 关闭对话框时清空输入框
      this.dialogVisible = false;
    },
    async saveApiKeys() {
      // 保存API keys，去除空格和重复项
      const newApiKeys = this.apiKeysInput
        .split("\n")
        .map((key) => key.trim())
        .filter((key) => key);
      this.apiKeysList = [...new Set([...this.apiKeysList, ...newApiKeys])];
      try {
        this.loading = true;
        await this.updateApiKeyList({
          shopId: this.apiKeyRoomShopId,
          apiKeysList: this.apiKeysList,
        });
        this.visible = false;
        this.$message({
          type: "success",
          message: "修改成功!",
        });
        this.dialogVisible = false; // 关闭对话框
        this.shopList = await this.getShopList();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$message({
          type: "error",
          message: "修改失败!",
        });
      }
    },
    removeApiKey(index) {
      // 从列表中移除API key
      this.apiKeysList.splice(index, 1);
      this.apiKeysInput = this.apiKeysList.join("\n");
    },
    async checkUserAuthorization() {
      const info = await this.getUserInfo();
      if (info.role === 1) {
        const hasAccess = true; // 假设我们根据某些条件判断用户是否有权限
        this.isShowApiPoolButton = hasAccess;
      }
    },
  },
  mounted() {
    // this.userForm.shopName = this.optionImg[0].label
    this.userForm.shopType = this.optionImg[0].value;
  },
};
</script>

<style lang="less" scoped>
.dialog-footer {
  text-align: right;
}
.collection-lib-btn {
  background-color: #67c23a;
  border-color: #67c23a;
  color: white;
  font-weight: bold;
  &:hover {
    background-color: #85ce61;
    border-color: #85ce61;
    transform: scale(1.05);
  }
  transition: all 0.3s ease;
}
</style>
